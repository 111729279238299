@import './../../../../scss/theme-bootstrap';

.cart-block {
  padding: 40px 0 0;
  &__content {
    padding: 0 2em 2em;
  }
  &__close {
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    cursor: pointer;
    padding: 0.25em;
  }
  &__header {
    @include pie-clearfix;
    border-bottom: solid 1px $color-black;
    padding: 0 0 0.5em;
    &__item-count {
      float: $rdirection;
    }
  }
  &__title {
    float: $ldirection;
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
    &__text {
      display: none;
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
        }
      }
    }
  }
  &__view-cart-link {
    float: $rdirection;
    text-align: $rdirection;
    margin-#{$rdirection}: 25px;
    a {
      text-decoration: underline;
    }
    .cart-block--empty & {
      display: none;
    }
  }
  &__empy-message {
    display: block;
    height: 5em;
    line-height: 5;
    text-align: $ldirection;
    .cart-block--nonempty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__footer__button {
    &--checkout,
    &--continue-shopping {
      margin-bottom: 10px;
    }
  }
  &__item-count {
    color: $color-dark-gray;
    font-size: 1.25em;
    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: block;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      display: block;
      padding: 1em 0 2em;
    }
    &--all {
      height: auto;
      max-height: 250px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }
  &__footer {
    border-top: solid 1px $color-black;
    &__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 1em;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__item-count {
      float: $ldirection;
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      color: $color-black;
      display: block;
      padding-top: 15px;
      &__label {
        font-size: 1.25em;
        float: $ldirection;
      }
      &__value {
        font-size: 1.25em;
        float: $rdirection;
      }
      .cart-block--added & {
        display: none;
      }
    }
    &__button {
      width: 100%;
      &--checkout {
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: inline-flex;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
    &--delivery-container {
      height: 2.5rem;
      line-height: 1.5;
      text-align: center;
      color: $color-pink-accent;
      padding-top: 14px;
    }
  }
}
